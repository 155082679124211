/* ========================== GENERAL ========================== */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap");
/* Scrollbar */
::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}
::-webkit-scrollbar-thumb {
  background: rgb(34, 47, 75, 1) !important;
  cursor: pointer !important;
}
::-webkit-scrollbar-track {
  background: rgb(244, 220, 43, 1) !important;
  border-radius: 20px !important;
}

body {
  font-family: "Montserrat", sans-serif !important;
  font-weight: bold !important;
}

button,
input,
textarea,
.ant-input-affix-wrapper,
.ant-select-selector,
.ant-picker {
  border-radius: 10px !important;
  text-align: center !important;
  resize: none !important;
}

hr {
  height: 0.5px !important;
  border: unset !important;
  background: rgb(34, 47, 75, 1) !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.custom-label {
  display: block !important;
  padding: 6px !important;
  border-radius: 10px !important;
  cursor: pointer !important;
  border: 1px solid rgb(217, 217, 217, 63%) !important;
}

.titulo {
  margin: unset !important;
}

.subtitulo {
  margin: 0px 0px 20px 0px !important;
}

.ant-layout-footer {
  padding: 20px !important;
}

.ant-table-wrapper {
  overflow-y: scroll !important;
}

.hide-column {
  display: none !important;
}

.ant-breadcrumb-link {
  cursor: pointer !important;
}

.ant-modal-pdf .ant-modal-body {
  padding: unset !important;
  text-align: center !important;
}

.react-pdf__Page__canvas {
  margin: auto !important;
  /* width: 100% !important; */
}

.react-pdf__message--error {
  text-align: center !important;
  padding: 20px !important;
}

#logo {
  width: 200px !important;
  margin: 10px 0px !important;
}

.ant-layout-header {
  padding: 10px 50px !important;
  line-height: unset !important;
}

.ant-layout-header .ant-menu {
  line-height: 45px !important;
}

/* ========================== COMPONENTS ========================== */
/* Error Component */
/* Mobile Component */
/* Load Component */

#mobile-component,
#error-component,
#load-component {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 100vh !important;
  text-align: center !important;
}

/* NotFound Component */

#notfound-component {
  justify-content: center !important;
  align-items: center !important;
  /* height: 100vh !important; */
  text-align: center !important;
}

#notfound-component img {
  width: 250px !important;
  height: 250px !important;
  margin: 10px auto !important;
}

#notfound-component svg {
  cursor: pointer !important;
}

#notfound-component #title {
  text-align: center !important;
  font-weight: bold !important;
  font-size: 4rem !important;
  color: rgb(34, 47, 75, 1) !important;
  text-shadow: 3px 3px 0px rgb(15, 210, 161, 1) !important;
  margin: unset !important;
}

#notfound-component #body {
  text-align: center !important;
  font-weight: bold !important;
  color: rgb(34, 47, 75, 1) !important;
  text-shadow: 3px 3px 0px rgb(15, 210, 161, 1) !important;
}

#notfound-component #div-icon-notfound {
  width: 45px !important;
  height: 45px !important;
  border: 1px solid rgb(34, 47, 75, 1) !important;
  margin: 5px auto 5px auto !important;
  padding: 5px !important;
  box-shadow: 3px 3px 0px 0px rgb(15, 210, 161, 1);
  -webkit-box-shadow: 3px 3px 0px 0px rgb(15, 210, 161, 1);
  -moz-box-shadow: 3px 3px 0px 0px rgb(15, 210, 161, 1);
  color: rgb(34, 47, 75, 1) !important;
}

#notfound-component #icon-notfound {
  color: rgb(34, 47, 75, 1) !important;
  font-size: 30px !important;
}

/* ========================== PAGES ========================== */
/* Login Page */
#form-login {
  width: 40% !important;
  margin: 50px auto !important;
  text-align: center !important;
}

.login-page-col-logo,
.login-page-col-title {
  text-align: center !important;
}

.login-page-logo {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.login-page-title {
  margin: unset !important;
}

/* Singup Page */
#form-singup {
  width: 40% !important;
  margin: 50px auto !important;
  text-align: center !important;
}

/* Dashboard Page */
#layout-main {
  min-height: 100vh !important;
}

#sede {
  color: #ffffff !important;
  width: 100% !important;
  margin: 5px auto !important;
  text-align: center !important;
  font-weight: bold !important;
}

#header {
  text-align: end !important;
  padding-right: 10px !important;
}

.button-logout {
  background: #ffffff !important;
  border-radius: 10px !important;
  border: unset !important;
}

.button-logout button {
  border: unset !important;
  font-size: 10px !important;
}

#content {
  padding: 20px !important;
}

#footer {
  text-align: center !important;
}

/* Registro Page */
#form-registro .ant-col {
  padding: 2px 5px !important;
}

#form-registro .ant-btn {
  margin-left: 5px !important;
}

#form-registro .ant-input-group-addon {
  background: transparent !important;
}

#form-registro .ant-form-item-control-input-content {
  text-align: center !important;
}

/* Detalle Solicitud Page */
#form-cliente,
#form-solicitud {
  text-align: center !important;
}

#form-asesor .ant-col,
#form-cliente .ant-col,
#form-solicitud .ant-col {
  padding: 2px 5px !important;
}

.col-padding-detalle-solicitud {
  text-align: center !important;
  padding: 10px !important;
}

.modal-voto-detalle-solicitud .ant-modal-body {
  padding: 0px 15px !important;
}

.modal-voto-detalle-solicitud .ant-row {
  margin: unset !important;
}

/* ========================== WIDGETS ========================== */
/* Leer PDF Widget */
.col-arrows-leer-pdf {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

/* Informe Final Widget */
.col-center {
  text-align: center !important;
}

/* ================ MEDIA QUERY ================ */
@media only screen and (max-width: 1000px) {
  #form-login {
    width: 80% !important;
    margin: 50px auto !important;
    text-align: center !important;
  }
  #form-singup {
    width: 80% !important;
    margin: 50px auto !important;
    text-align: center !important;
  }

  #sede {
    background: #000000 !important;
    width: 100% !important;
    border-radius: 10px;
    text-align: center !important;
  }

  .button-logout {
    background: #ffffff !important;
    border-radius: 10px !important;
    border: unset !important;
    margin-bottom: 10px !important;
  }

  .logo {
    float: left !important;
    width: 150px !important;
  }

  #logo {
    width: 100% !important;
  }

  #content {
    padding: 20px !important;
    margin-top: 50px !important;
  }
}

@media only screen and (max-width: 1000px) and (orientation: landscape) {
  #form-login {
    width: 50% !important;
    margin: 50px auto !important;
    text-align: center !important;
  }
  #form-singup {
    width: 80% !important;
    margin: 50px auto !important;
    text-align: center !important;
  }

  #sede {
    background: #000000 !important;
    width: 100% !important;
    border-radius: 10px;
    text-align: center !important;
  }

  .button-logout {
    background: #ffffff !important;
    border-radius: 10px !important;
    border: unset !important;
    margin-bottom: 10px !important;
  }

  .logo {
    float: left !important;
    width: 150px !important;
  }

  #logo {
    width: 100% !important;
  }

  #content {
    padding: 20px !important;
    margin-top: 50px !important;
  }
}
